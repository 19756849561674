var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{staticStyle:{"max-width":"500px"},attrs:{"append-icon":"mdi-cloud-search-outline","label":_vm.$t('phrases.Search'),"placeholder":_vm.$t('phrases.Searchterm'),"solo":""},model:{value:(_vm.searchProp),callback:function ($$v) {_vm.searchProp=$$v},expression:"searchProp"}}),_c('v-data-table',{staticClass:"elevation-2 mitfit-table",attrs:{"loading":_vm.preloader,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.items,"items-per-page":10,"search":_vm.searchProp,"edit":_vm.edit,"trash":_vm.trash,"copy":_vm.copy,"leads":_vm.leads,"preview":_vm.preview},scopedSlots:_vm._u([{key:"item.usersType",fn:function(ref){
var item = ref.item;
return [(item.usersType === '1')?_c('span',[_c('v-chip',{attrs:{"color":"green","text-color":"white"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-crown ")]),_vm._v(" MASTER ")],1)],1):_vm._e(),(item.usersType === '2')?_c('span',[_c('v-chip',{attrs:{"color":"primary","text-color":"white"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-circle ")]),_vm._v(" Admin ")],1)],1):_vm._e()]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [(item.created && item.created !== '0000-00-00 00:00:00')?_c('span',[_vm._v(_vm._s(_vm.formatDate(item.created)))]):_vm._e()]}},{key:"item.updated",fn:function(ref){
var item = ref.item;
return [(item.updated)?_c('span',[_vm._v(_vm._s(_vm.formatDate(item.updated)))]):_vm._e()]}},{key:"item.actionIcon",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.actionIcon)}})]}},{key:"item.updatedate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.timestampToDate(item.updatedate))+" "+_vm._s(_vm.$t("phrases.oclock"))+" "+_vm._s(_vm.$t("by"))+" "),(item.admin_name)?_c('span',[_vm._v(_vm._s(item.admin_name))]):_c('span',[_vm._v("FACEFORCE")])]}},{key:"item.summary",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.summary)}})]}},{key:"item.gender",fn:function(ref){
var item = ref.item;
return [(item.person_sex === 'male')?_c('span',[_vm._v(" "+_vm._s(_vm.$t("gender.Mr"))+" ")]):(item.gender === 'female')?_c('span',[_vm._v(" "+_vm._s(_vm.$t("gender.Mrs"))+" ")]):(item.gender === 'diverse')?_c('span',[_vm._v(" "+_vm._s(_vm.$t("gender.Diverse"))+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [(item.isActive === '0')?_c('span',[_vm._v(_vm._s(_vm.$t("no")))]):_c('span',[_vm._v(_vm._s(_vm.$t("yes")))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-item-group',{staticClass:"d-flex flex-row"},[(_vm.edit)?_c('div',[[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"fab":"","x-small":"","color":"green","elevation":"0"},on:{"click":function($event){return _vm.editItem(item, index)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("controles.Edit")))])])]],2):_vm._e(),(_vm.watch)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"fab":"","x-small":"","color":"blue","elevation":"0"},on:{"click":function($event){return _vm.watchItem(item, index)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("controles.Watch")))])])],1):_vm._e(),(_vm.copy)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"fab":"","x-small":"","color":"orange","elevation":"0"},on:{"click":function($event){return _vm.copyItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("controles.Duplicate")))])])],1):_vm._e(),(_vm.preview)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"fab":"","x-small":"","color":"blue","elevation":"0"},on:{"click":function($event){return _vm.previewItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("controles.Preview")))])])],1):_vm._e(),(_vm.exporting)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"fab":"","x-small":"","color":"deep-purple lighten-1","elevation":"0"},on:{"click":function($event){return _vm.exportItem(item, index)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-file-export")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("controles.Export")))])])],1):_vm._e(),(_vm.trash)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"fab":"","x-small":"","color":"red","elevation":"0","disabled":_vm.disabledByPermission(_vm.permissions.delete)},on:{"click":function($event){return _vm.deleteItem(item, index)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("controles.Delete")))])])],1):_vm._e()])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
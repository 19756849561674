<template>
  <div>
    <v-text-field
      v-model="searchProp"
      append-icon="mdi-cloud-search-outline"
      :label="$t('phrases.Search')"
      :placeholder="$t('phrases.Searchterm')"
      solo
      style="max-width: 500px"
    ></v-text-field>

    <v-data-table
      class="elevation-2 mitfit-table"
      :loading="preloader"
      loading-text="Loading... Please wait"
      :headers="headers"
      :items="items"
      :items-per-page="10"
      :search="searchProp"
      :edit="edit"
      :trash="trash"
      :copy="copy"
      :leads="leads"
      :preview="preview"
    >
      <template v-slot:[`item.usersType`]="{ item }">
        <span v-if="item.usersType === '1'">
          <v-chip color="green" text-color="white">
            <v-icon left> mdi-crown </v-icon>
            MASTER
          </v-chip>
        </span>
        <span v-if="item.usersType === '2'">
          <v-chip color="primary" text-color="white">
            <v-icon left> mdi-account-circle </v-icon>
            Admin
          </v-chip>
        </span>
      </template>

      <template v-slot:[`item.created`]="{ item }">
        <span v-if="item.created && item.created !== '0000-00-00 00:00:00'">{{
          formatDate(item.created)
        }}</span>
      </template>

      <template v-slot:[`item.updated`]="{ item }">
        <span v-if="item.updated">{{ formatDate(item.updated) }}</span>
      </template>

      <template v-slot:[`item.actionIcon`]="{ item }">
        <span v-html="item.actionIcon"></span>
      </template>

      <template v-slot:[`item.updatedate`]="{ item }">
        {{ timestampToDate(item.updatedate) }} {{ $t("phrases.oclock") }}
        {{ $t("by") }}
        <span v-if="item.admin_name">{{ item.admin_name }}</span>
        <span v-else>FACEFORCE</span>
      </template>

      <template v-slot:[`item.summary`]="{ item }">
        <span v-html="item.summary"></span>
      </template>

      <template v-slot:[`item.gender`]="{ item }">
        <span v-if="item.person_sex === 'male'">
          {{ $t("gender.Mr") }}
        </span>

        <span v-else-if="item.gender === 'female'">
          {{ $t("gender.Mrs") }}
        </span>

        <span v-else-if="item.gender === 'diverse'">
          {{ $t("gender.Diverse") }}
        </span>

        <span v-else> - </span>
      </template>

      <!-- 1/0 = Ja(Nein) -->

      <template v-slot:[`item.isActive`]="{ item }">
        <span v-if="item.isActive === '0'">{{ $t("no") }}</span>
        <span v-else>{{ $t("yes") }}</span>
      </template>

      <!-- Adding clickable elements -->

      <template v-slot:[`item.actions`]="{ item, index }">
        <v-item-group class="d-flex flex-row">
          <div v-if="edit">
            <template>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    color="green"
                    @click="editItem(item, index)"
                    v-bind="attrs"
                    v-on="on"
                    class="mx-1"
                    elevation="0"
                  >
                    <v-icon color="white">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("controles.Edit") }}</span>
              </v-tooltip>
            </template>
          </div>

          <div v-if="watch">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  x-small
                  color="blue"
                  @click="watchItem(item, index)"
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                  elevation="0"
                >
                  <v-icon color="white">mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("controles.Watch") }}</span>
            </v-tooltip>
          </div>

          <div v-if="copy">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  x-small
                  color="orange"
                  @click="copyItem(item)"
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                  elevation="0"
                >
                  <v-icon color="white">mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("controles.Duplicate") }}</span>
            </v-tooltip>
          </div>

          <div v-if="preview">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  x-small
                  color="blue"
                  @click="previewItem(item)"
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                  elevation="0"
                >
                  <v-icon color="white">mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("controles.Preview") }}</span>
            </v-tooltip>
          </div>

          <div v-if="exporting">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  x-small
                  color="deep-purple lighten-1"
                  @click="exportItem(item, index)"
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                  elevation="0"
                >
                  <v-icon color="white">mdi-file-export</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("controles.Export") }}</span>
            </v-tooltip>
          </div>

          <div v-if="trash">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  x-small
                  color="red"
                  @click="deleteItem(item, index)"
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                  elevation="0"
                  :disabled="disabledByPermission(permissions.delete)"
                >
                  <v-icon color="white">mdi-trash-can</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("controles.Delete") }}</span>
            </v-tooltip>
          </div>
        </v-item-group>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: null,
      searchProp: this.search,
      toggle_exclusive: undefined,
      currentTimestamp: Math.floor(Date.now() / 1000),
    };
  },

  methods: {
    disabledByPermission(value) {
      if (value !== "1" && this.permissions.master !== "1") {
        return true;
      } else {
        return false;
      }
    },

    nextYear(startYear) {
      return parseInt(startYear) + 1;
    },

    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },

    formatDate(date) {
      const dt = new Date(date);
      const d = this.addZero(dt.getDate()); //Month from 0 to 11
      const m = this.addZero(dt.getMonth() + 1); //Month from 0 to 11
      const y = dt.getFullYear();
      const h = this.addZero(dt.getHours());
      const i = this.addZero(dt.getMinutes());
      //const s = this.addZero(dt.getSeconds());
      return (
        d +
        "." +
        m +
        "." +
        y +
        " " +
        h +
        ":" +
        i +
        " " +
        this.$t("phrases.oClock")
      );
    },

    zeroPad(num, places) {
      var zero = places - num.toString().length + 1;
      return Array(+(zero > 0 && zero)).join("0") + num;
    },

    timestampToDate(timestamp) {
      // Converts a timestamp to Date
      timestamp = parseInt(timestamp * 1000);
      var currentDate = new Date(timestamp);
      var date = this.zeroPad(currentDate.getDate(), 2);
      var month = this.zeroPad(currentDate.getMonth() + 1, 2);
      var year = currentDate.getFullYear();
      var hours = this.zeroPad(currentDate.getHours(), 2);
      var minutes = this.zeroPad(currentDate.getMinutes(), 2);
      var seconds = this.zeroPad(currentDate.getSeconds(), 2);
      var formattedTime =
        date +
        "." +
        month +
        "." +
        year +
        ", " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
      return formattedTime;
    },

    /* Emitting data to parent component
      Die Daten werden in der emittedAction() Method der Elternkomponente verarbeitet
      */

    itemAction(item, index) {
      this.$emit("tableEvent", {
        action: "editItem",
        itemId: item.id,
        itemIndex: index,
        itemData: item, // emit the complete data of that item
      }); // emit to parent-component
    },

    editItem(item, index) {
      this.$emit("tableEvent", {
        action: "editItem",
        itemId: item.id,
        itemUuid: item.uuid,
        itemIndex: index,
        itemData: item, // emit the complete data of that item
      }); // emit to parent-component
    },

    watchItem(item, index) {
      this.$emit("tableEvent", {
        action: "watchItem",
        itemId: item.id,
        itemIndex: index,
        itemData: item, // emit the complete data of that item
      }); // emit to parent-component
    },

    activateItem(item, index) {
      this.$emit("tableEvent", {
        action: "activateItem",
        itemId: item.id,
        itemIndex: index,
        itemData: item, // emit the complete data of that item
      }); // emit to parent-component
    },

    safeItem(item) {
      this.$emit("tableEvent", {
        action: "safeItem",
        itemId: item.id,
      }); // emit to parent-component
    },

    deleteItem(item, index) {
      this.$emit("tableEvent", {
        action: "deleteItem",
        itemId: item.id,
        itemIndex: index,
        item: item,
      }); // emit to parent-component
    },
    copyItem(item) {
      this.$emit("tableEvent", {
        action: "copyItem",
        itemId: item.id,
      }); // emit to parent-component
    },
    duplicateItem(item) {
      this.$emit("tableEvent", {
        action: "duplicateItem",
        itemId: item.id,
      }); // emit to parent-component
    },
    abortItem(item) {
      this.$emit("tableEvent", {
        action: "abortItem",
        itemId: item.id,
      }); // emit to parent-component
    },
    leadsItem(item) {
      this.$emit("tableEvent", {
        action: "leadsItem",
        itemId: item.id,
      }); // emit to parent-component
    },
    previewItem(item) {
      this.$emit("tableEvent", {
        action: "previewItem",
        itemId: item.id,
      }); // emit to parent-component
    },
    statsItem(item) {
      this.$emit("tableEvent", {
        action: "statsItem",
        itemId: item.id,
      }); // emit to parent-component
    },
    exportItem(item, index) {
      this.$emit("tableEvent", {
        action: "exportItem",
        itemId: item.id,
        itemIndex: index,
      }); // emit to parent-component
    },
    passwordProtectItem(item, index) {
      this.$emit("tableEvent", {
        action: "passwordProtection",
        itemId: item.id,
        itemIndex: index,
        itemData: item, // emit the complete data of that item
      }); // emit to parent-component
    },
  },

  name: "DataTable",
  props: [
    "headers",
    "items",
    "edit",
    "watch",
    "trash",
    "copy",
    "leads",
    "preview",
    "stats",
    "exporting",
    "passwordProtection",
    "preloader",
    "permissions",
  ],
};
</script>

<style>
.mitfit-table thead {
  background-color: #282d35 !important;
  color: #ffffff !important;
}

.mitfit-table thead th {
  color: #ffffff !important;
}

.mitfit-table td {
  padding: 1em !important;
}
</style>
